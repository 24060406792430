import { CircularProgress, Flex } from '@stars-ecom/shared-atoms-ui'
import {
  ApiContext,
  CartContext,
  CheckoutPaymentSummary,
  PaymentContextProvider,
  PaymentModeList,
  UserContext
} from '@stars-ecom/shared-organisms-ui'
import { graphql, navigate } from 'gatsby'
import { debounce } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import { siteFormatter } from '../utils/metaTitleFormatter'

const PaymentPage = (props) => {
  const { pageContext, location } = props
  const { cgv } = pageContext

  const [paymentMethod, _setPaymentMethod] = useState()

  const setPaymentMethod = (v) => _setPaymentMethod(v)
  const [isLoading, setIsLoading] = useState(true)
  const apiContext = useContext(ApiContext)
  const currentUser = useContext(UserContext)
  const currentCart = useContext(CartContext)
  const [hipayErrorMessage, setHipayErrorMessage] = useState('')

  useEffect(() => {
    //check if user went back without validating 3D-S
    const params = new URLSearchParams(location?.search) || {}
    const resp = params.get('resp')
    const reference = params.get('reference')

    if (location.search.includes('shipping')) {
      const stateObj = { previousUrl: 'shipping' }
      history.replaceState(stateObj, '', 'payment')
      if (
        currentCart?.state === 'PaymentIn3dsProcess' ||
        currentCart?.state === 'ArrangingPayment'
      ) {
        navigate('/checkout/payment/invalidstate')
      }
    } else {
      if (!resp && !reference)
        setTimeout(() => {
          updateContext()
        }, 1000)
    }
  }, [currentCart?.state])

  useEffect(() => {
    if (currentUser?.id) {
      setHipayErrorMessage('')
      debounce(check3DSecure, 200)()
    }
  }, [currentUser?.id])

  const updateContext = async () => {
    await apiContext.AccountApi.getCurrentUser()
    if (currentCart?.state === 'PaymentIn3dsProcess' || currentCart?.state === 'ArrangingPayment') {
      navigate('/checkout/payment/invalidstate')
    }
  }

  const check3DSecure = async () => {
    const params = new URLSearchParams(location?.search) || {}
    const resp = params.get('resp')
    const reference = params.get('reference')
    if (resp && reference) {
      const result = await apiContext?.OrderApi?.validate3ds(reference, resp)
      if (result && result?.success && resp === 'Accept') {
        navigate('/checkout/order-confirmation', {
          state: { order: result.order },
          replace: true
        })
      } else {
        // si resp=Decline, redirect to PaymentInvalidStatePage
        navigate('/checkout/payment/invalidstate')
      }
    }

    setIsLoading(false)
  }

  return (
    <Flex direction="column" align="center" justify="flex-start" flexGrow={1} w="100%">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={`Paiement - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}
        />
        <meta name="robots" content="noindex" />
        <title>{`Paiement - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}</title>
        <script src="https://libs.hipay.com/js/sdkjs.js"></script>
        <link href="https://libs.hipay.com/themes/material.css" rel="stylesheet" />
      </Helmet>
      <PaymentContextProvider
        value={{
          paymentMethod,
          setPaymentMethod
        }}>
        {isLoading ? (
          <CircularProgress isIndeterminate />
        ) : (
          <Flex
            direction={{ base: 'column-reverse', md: 'row' }}
            width={{ base: '100%', md: '1000px' }}
            maxWidth={{ base: '420px', md: '1000px' }}
            paddingLeft={{ base: '10px', md: '15px' }}
            paddingRight={{ base: '10px', md: '15px' }}
            justify="space-between"
            style={{ marginTop: '10px' }}
            gridGap="20px">
            <PaymentModeList
              closeErrorMsg={() => setHipayErrorMessage('')}
              error={hipayErrorMessage}
              cgv={cgv}
            />
            <CheckoutPaymentSummary />
          </Flex>
        )}
      </PaymentContextProvider>
    </Flex>
  )
}

export const query = graphql`
  {
    vendure {
      paymentMethods {
        items {
          id
          name
          code
          customFields {
            nbDebit
            applicationFee
          }
          checker {
            args {
              name
              value
            }
          }
        }
      }
    }
  }
`

PaymentPage.pageType = 'payment'

export default PaymentPage
